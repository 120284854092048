module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#001c71","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["X-Frame-Options: ALLOW-FROM https://accruent.seismic.com","X-XSS-Protection: 1; mode=block","X-Content-Type-Options: nosniff","Strict-Transport-Security: max-age=31536000; includeSubDomains","Access-Control-Allow-Origin: *"],"/fonts/*":["Cache-Control: public, max-age=31536000, immutable"],"/Accruent-Logo-color.svg":["Cache-Control: public, max-age=31536000, immutable"],"/accruent-logo-white.svg":["Cache-Control: public, max-age=31536000, immutable"],"/ems_logo.svg":["Cache-Control: public, max-age=31536000, immutable"],"/mc-logo.svg":["Cache-Control: public, max-age=31536000, immutable"],"/accruent-logo-colortext.png":["Cache-Control: public, max-age=31536000, immutable"]},"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Accruent","short_name":"Accruent","start_url":"/","background_color":"#2980b9","theme_color":"#2980b9","display":"browser","icon":"src/favicons/favicon-ems-256px.png","cache_busting_mode":"none","orientation":"portrait","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T528L3F","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
